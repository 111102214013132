console.log('Designed and build by Granite Digital ( http://www.granite.ie/ )');

// $(window).resize(function(){
// 	if ($( document ).width() > 975) {
// 		location.reload();
// 	}
// });

$( document ).ready(function() {
	// new range slider

	// one app costs 25.45 per month
	// annual price is beneficiary of a reduction
	// thus var annualPriceCorrected is the corrected value

	var appPrice = 25.45;
	var annualPriceCorrected = 11.3;


	var rangeSlider = function(){
	var slider = $('.range-slider'),
	    range = $('.range-slider--range'),
	    value = $('.range-slider--value');

	slider.each(function(){

	  value.each(function(){
			console.log('iteration on');
	    var value = $(this).prev().attr('value');
	    $(this).html(value);
	  });

	  range.on('input', function(){

	    $(this).next(value).html(this.value);
			if( $('.total-price .type').html() == 'per month'){
				$('.total-price h1 span').html(round2Fixed(this.value * appPrice));
			}else {
				$('.total-price h1 span').html(round2Fixed(this.value * appPrice * annualPriceCorrected));
			}

			// #grammar :)
			if (this.value > 1)
			{
				$(this).parent().find('p').first().html('apps');
			}
			else {
				{
					$(this).parent().find('p').first().html('app');
				}
			}
	  });
	});
	};

	rangeSlider();

	// switch
	$('#switch-month-year').on('click', function(e){
		var value = $('.total-price h1 span').html();
		if( $('.total-price .type').html() == 'per month'){

			$('.total-price .type').html('per year');
			$('.price-switch label').html('yearly price');
			$('.total-price h1 span').html(round2Fixed(value * annualPriceCorrected))

		}else {

			$('.total-price .type').html('per month');
			$('.price-switch label').html('monthly price');
			$('.total-price h1 span').html(round2Fixed(value / annualPriceCorrected))
		}
	});
	//init WOW.JS
	new WOW().init();

	// adapt the coloration of the navbar while scroll
	$( window ).scroll(function() {
		var scroll = getCurrentScroll();
		if ( scroll > 0 ) {
			$('nav').addClass('colored');
		}
		else {
			$('nav').removeClass('colored');
		}
	});

	function getCurrentScroll() {
		return window.pageYOffset;
	}

	//change the background main image

	$('.landing-area').each(function(){
		var oElement = $(this);
		//we split our data attribute and stock it in an array
		var requiredPaths = $(oElement).data("background").split(";");

		oElement.attr("data-background-index", 0);

		// console.log('content of the array :  ' + requiredPaths);
		var dataCount = requiredPaths.length;
		// console.log('number of background :  ' + dataCount);

		setInterval(function(){
			var iCurrentIdx = parseInt($(oElement).attr("data-background-index"));
			var aRequiredPaths = $(oElement).data("background").split(";");
			if ( iCurrentIdx >= (aRequiredPaths.length - 1) ){iCurrentIdx = -1;}
			iCurrentIdx += 1;
			// console.log("Showing img: " + aRequiredPaths[iCurrentIdx]);
			$(oElement).css("background-image", "url("+aRequiredPaths[iCurrentIdx]+")");
			$(oElement).attr("data-background-index", iCurrentIdx);
		}, 4000);
	});
/*
		var index = 0;
		  $(".landing-area").keydown(function() {
		    if (index >= requiredPaths.length)
		      index = 0; // reset back to first color
		    $(".landing-area").css("background-image", requiredPaths[index]);
		    index++;
		  });
*/
	// var i;
	// for (i = 0; i < dataCount; ++i) {
	// 	setInterval(function () {
	//     	$('.landing-area').css('background-image', 'url(' + requiredPaths[i] + ')');
	//     }, 5000);
	//     console.log('iteration numeer :  ' + i);
	// }





	// Config sliders (SwiperJS)
	var mySwiper = new Swiper ('.landing-slider', {
		loop: true,
		slidesPerView: 5,
		centeredSlides:true,
		// nextButton: '.swiper-button-next',
		// prevButton: '.swiper-button-prev',
		breakpoints: {
		// until this value (max-width)
		   600: {
		     slidesPerView: 1,
		     nextButton: '.swiper-button-next',
		     prevButton: '.swiper-button-prev',
		   },
		   1440: {
		     slidesPerView: 3
		   }
		 }
	});
	var mySwiper = new Swiper ('.brand-slider', {
	  loop: true,
	  autoplay:750,
	  slidesPerView: 5,
	  breakpoints: {
	     640: {
	       slidesPerView: 2,
	       spaceBetween: 10
	     },
	     768: {
	       slidesPerView: 3,
	       spaceBetween: 30
	     },
	     1200: {
	       slidesPerView: 3,
	       spaceBetween: 40
	     },
	     1440: {
	       slidesPerView: 4,
	       spaceBetween: 40
	     }
	   }
	});


	var appsDetails = new Swiper ('.apps-slider .apps-detail', {
		// loop:true,
		slidesPerView:1,
		// loopAdditionalSlides:3
		breakpoints:{
			991:{
				loop:true,
				loopAdditionalSlides:3
			}
		}
	});
	var appsThumbs = new Swiper('.apps-slider .apps-thumb', {
		// loop:true,
		// centeredSlides:true,
		slidesPerView:5,
		simulateTouch: true,
		threshold: 10000,
		preventClicksPropagation:false,
		preventClicks:false,
		breakpoints:{
			991:{
				loop:true,
				slidesPerView:3,
				threshold: 0,
				onTap: null
			}
		}
		,
		onTap: function (swiper) {
				appsDetails.slideTo(appsThumbs.clickedIndex)
				// appsDetails.swipeTo(swiper.clickedSlideIndex);

	    		// console.log('i go to' + ' ' + appsThumbs.clickedIndex);

	    		// because starting point is 1 for html, 0 for swiper add 1
	    		var thisSlideIndex = appsThumbs.clickedIndex + 1;
	    		// console.log('this slide is ' + thisSlideIndex);
	    		$('.apps-thumb .swiper-slide').removeClass('swiper-slide-active');
	    		$('.apps-thumb .swiper-slide:nth-child('+ thisSlideIndex +')').addClass('swiper-slide-active');
    	}
	});

	if ($( document ).width() < 975) {
		appsDetails.params.control = appsThumbs;
		appsThumbs.params.control = appsDetails;
	}

	var testimonialSlider = new Swiper('.testimonials-slider', {
		loop:true,
		slidesPerView:1,
		loopAdditionalSlides:4,
		// autoplay: 2500,
		breakpoints: {
			767: {
				loopAdditionalSlides:0
			},
			1200: {
				loopAdditionalSlides:2
			}
		}
	});
	var testimonialThumb = new Swiper('.testimonials-thumb', {
		loop:true,
		slidesPerView: 5,
		centeredSlides:true,
		breakpoints: {
		   767: {
		   	 centeredSlides:false,
		     slidesPerView: 1
		   },
		   1200: {
		   	slidesPerView: 3
		   }
		}
	});
	testimonialSlider.params.control = testimonialThumb;
	testimonialThumb.params.control = testimonialSlider;
});

function round2Fixed(value) {
  value = +value;

  if (isNaN(value))
    return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + 2) : 2)));

  // Shift back
  value = value.toString().split('e');
  return (+(value[0] + 'e' + (value[1] ? (+value[1] - 2) : -2))).toFixed(2);
}